.reports-map{
  position: relative;

  &__draw-control{
    display: block;

    &--hidden{
      display: none !important;
    }
  }

  &__searchFilter{
    position: absolute;
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    left: 0;
    right: 0;
    top: 8px;

    & > .custom-checkbox__wrapper{
      background-color: $white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      border: 1px solid $lighter-gray;
      box-sizing: border-box;
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
  }

  &__layers{
    &__icon{
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: $white;
      align-items: center;
      justify-content: center;
      display: flex;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      z-index: 300;
      cursor: pointer;
    }
    &__container{
      max-width: 367px;
      width: 100%;
      min-width: 200px;
      min-height: 100px;
      max-height: 348px;
      overflow-y: scroll;
      background-color: $white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      z-index: 300;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      padding: 1rem 1.25rem;
      position: relative;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      &-close{
        top: 1rem;
        right: 1rem;
        position: absolute;
        fill: $black;
        cursor: pointer;
      }

      &-label{
        font-family: 'SFPro-Text', sans-serif;
        font-size: 0.875rem;
        line-height: 1.25;
        display: flex;
        text-align: left;
        color: $medium-gray;
        margin-bottom: 12px;
      }
      &-radios{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
      }
    }
    &__list{
      display: flex;
      flex-direction: column;
      &-element{
        margin-bottom: 1.25rem;
        & > label {
          text-align: left !important;
        }
      }
    }
    &__box{
      position: absolute;
      right: 8rem;
      bottom: 10px;
      z-index: 300;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  &__zoomControl{
    flex-direction: row-reverse !important;
    border-radius: 8px !important;
    border: 1px solid $lighter-gray !important;
    width: 6rem;
    height: 3rem;

    & > #zoomIn, > #zoomOut{
      width: 3rem !important;
      height: 3rem !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;

      &:hover{
        background-color: $lighter-gray;
      }
    }

    & > #zoomIn {
      background-image: url('../../../assets/images/zoom_plus.svg') !important;
      background-size: 50% !important;
      background-position: center !important;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    & > #zoomOut{
      background-image: url('../../../assets/images/zoom_minus.svg') !important;
      background-size: 50% !important;
      background-position: center !important;
      border-right: 1px solid $lighter-gray !important;
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

  }

}


.mapbox-gl-draw_line, .mapbox-gl-draw_point, .mapbox-gl-draw_combine, .mapbox-gl-draw_uncombine{
  display: none !important;
}

.mapboxgl-popup-content{
  padding: 0 !important;
  border-radius: 8px !important;
}

.map-popup{
  &__wrapper{
    width: 296px;
    min-height: 361px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 8px ;
    position: relative;
    flex: 0;
  }

  &__close{
    display: inline-flex;
    gap: 5px;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 3;
  }

  &__main-image{
    width: 100%;
    min-height: 50px;
    max-height: 166px; // 16:9
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    overflow: hidden;

    img { width: 100%; }
  }

  &__content{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    //flex: 0;

    &-title{
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: bold;
      text-align: left;
      font-size: 1rem;
      line-height: 1.5;
      color: $darker-gray;
    }
    &-description{
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: normal;
      text-align: left;
      font-size: 0.875rem;
      line-height: 1.25;
      color: $medium-gray;
      margin-bottom: 8px;
    }
  }

  &__author-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 22px;

    &--wrapper{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
    }
  }

  &__author-label{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: bold;
    text-align: left;
    font-size: 1rem;
    line-height: 1.5;
    color: $darker-gray;
    }
  &__author-value{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    font-size: 0.875rem;
    line-height: 1.25;
    color: $medium-gray;
  }

  &__assets-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__assets-element{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 22px;

    &-image{
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 8px;

      &--image{
        background-image: url('../../../assets/images/image_popup.svg');
        width: 16px;
        height: 16px;
      }
      &--video{
        background-image: url('../../../assets/images/video_popup.svg');
        width: 16px;
        height: 12px;
      }
      &--audio{
        background-image: url('../../../assets/images/audio_popup.svg');
        width: 14px;
        height: 16px;
      }
      &--document{
        background-image: url('../../../assets/images/document_popup.svg');
        width: 14px;
        height: 16px;
      }
    }
  }
}
