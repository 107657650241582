.report-activityfeed {
  background: $white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 0.5rem;

  &-title{
    &-container{
      border-bottom: 1px solid $lighter-gray;
    }
    width: 100%;
    font-size: 1.375rem;
    font-family: "SFPro-Text", sans-serif;
    font-weight: bold;
    text-align: left;
    line-height: 1.1;
    padding: 1.25rem 1.5rem;
  }

  &-body{
    &-container{
      padding: 2rem;
      max-height: 450px;
      overflow: hidden;
      overflow-y: auto;
      &:empty { margin: 0; }
    }
  }

  &-empty {
    color: $medium-gray;
    text-align: left;
  }

  &-info{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
    min-height: 75px;
    &-profile{
      &-section{
        display: flex;
        flex-direction: column;
        margin-right: 10px;
      }
      &-icon{
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: $profile-blue;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        line-height: 1.5;
        color: $black;
        font-family: 'SFPro-Text', sans-serif;
        text-align: center;
      }
      &-line{
        margin-bottom: 0;
        margin-top: 0;
        flex: 1;
      }
    }
    &-status{
      height: 3rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 0.5rem;
      &-title, &-text, &-date{
        font-family: 'SFPro-Text', sans-serif;
        font-size: 1rem;
        line-height: 1.5;
      }

      &-title{
        color: $darker-gray;
        font-weight: bold;
        text-transform: capitalize;
      }
      &-text{
        margin-left: 5px;
        color: $darker-gray;
        .assignee { text-transform: capitalize; }
      }
      &-date{
        margin-left: 0.5rem;
        color: $light-gray;
      }
    }
    &-comment{
      font-family: 'SFPro-Text', sans-serif;
      font-size: 1rem;
      line-height: 1.5;
      color: $darker-gray;
      text-align: left;
    }

    &-files{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 0.5rem;
      &-video-highlight{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-element{
        background-color: #F2F8F7;
        border-radius: 0.5rem;
        width: 88px;
        height: 66px;
        margin-bottom: 10px;
        margin-right: 10px;
        transition: transform .5s;
        &:hover{
          transform: scale(1.2);
          cursor: pointer;
        }
        &-video{
          background-image: url('../../../assets/images/video48.png');
          background-position: center center;
          background-repeat: no-repeat;
        }
        &-audio{
          background-image: url('../../../assets/images/audio48.png');
          background-position: center center;
          background-repeat: no-repeat;
        }
        &-pdf{
          background-image: url('../../../assets/images/pdf48.png');
          background-position: center center;
          background-repeat: no-repeat;
        }
        &-image{
          background-image: url('../../../assets/images/image48.png');
          background-position: center center;
          background-repeat: no-repeat;
        }

        &-pointer{
          cursor: pointer;
        }
      }
    }

    &:last-child{
      .report-activityfeed-info-profile-line{
        flex: 0;
        display: none;
      }
    }
  }

  &-submit{
    padding: 2rem;

    // modal
    &-buttons {
      margin-top: 1.875rem;
      margin-bottom: 2.25rem;
      display: flex;
      justify-content: center;
      .button__wrapper {
        margin: 0 0.75rem;
      }
    }

    &-fileinput{
      display: none;
    }

    // add update
    &-buttons-container{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
      flex: 1;

      .button__wrapper {
        margin: 0 0.5rem;
      }
    }

    &-files{
      &-container{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-top: 0.5rem;
        overflow: hidden;
        overflow-x: auto;

        &::-webkit-scrollbar {
          height: 0.5rem;
        }
        &::-webkit-scrollbar-track{
          background-color: $white;
          height: 0.5rem;
        }
        &::-webkit-scrollbar-thumb {
          height: 6px;
          background: $smooth-gray;
          border-radius: 50px;
        }
      }
      &-info{
        font-size: 0.875rem;
        overflow: hidden;
        min-width: 98px;
        margin: 0; padding: 0.5rem;
        word-break: break-all;

        >*:hover {
          transform: none;
          cursor: initial;
          //margin: 0;
        }
      }
    }
    &-modal{
      padding-left: 60px;
      padding-right: 60px;
      &-mini{
        width: 675px !important;
        @include on-tablet {
          width: 80% !important;
        }
      }

      @include on-medium-device{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      & > .admin-modal__header {
        height: auto;
        margin-top: 64px;
      }
    }
    &-input{
      border: none;
      border-radius: 0;
      font-family: 'SFPro-Text', sans-serif;
      font-size: 1rem;
      line-height: 1.5;
      max-width: calc(100% - 2.5rem);
      margin: 1rem 1.25rem;
      padding: 0.5rem;
    }

    &-box{
      box-sizing: border-box;
      border: 1px solid $lighter-gray;
      border-radius: 0.5rem;

      .text-input__error { margin-left: 1.25rem; }
    }

    &-actions{
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $lighter-gray;
      margin: 1rem 1.25rem 0;
      text-align: left;
      justify-content: space-between;
    }

    &-action {
      &-group{
        display: flex;
        flex-wrap: wrap;
        margin: 1rem auto;
      }
      &-button{
        svg{
          width: 1rem;
          height: 1rem;
          fill: $medium-gray;
          margin-right: 0.5rem;
        }
        font-family: 'SFPro-Text', sans-serif;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5;
        color: $medium-gray;
        display: flex;
        align-items: center;
        white-space: nowrap;
        justify-content: center;
        cursor: pointer;
        margin: 0.5rem;
        //width: 100%;
      }
    }
  }
}

// Perfect is the enemy of good
@media screen and (min-width: 1280px) {
  .reports-container__small-panel--hidden ~ .reports-container__large-panel {
    .report-activityfeed-submit-action-group,
    .report-activityfeed-submit-buttons-container {
      width: 50%;
    }
    .report-activityfeed-submit-buttons-container {
      justify-content: flex-end;
    }
  }
}

@media screen and (min-width: 1920px) {
  .report-activityfeed-submit-buttons-container {
    justify-content: flex-end;
  }
}
