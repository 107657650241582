.report-view{
  &__container{
    height: 100%;
    width: 45%;
    flex-grow: 1;
    position: relative;
    padding: 1rem 1.5rem 2rem;
    overflow-y: scroll;

    &-padding-top{
      padding-top: 2rem;
    }
    &-header{
      display: flex;
      justify-content: space-between;
      &-icons {
        display: flex;
        justify-content: space-between;
        & > .rounded-icon_wrapper{
          margin-right: 1rem;
        }
      }
      &-close{
        display: flex;
        justify-content: space-between;
        & > .button__wrapper{
          margin-bottom: 0;
          margin-right: 1rem
        }
      }
    }

    &-box-edit{
      .report-view__container-body-box {
        max-width: 1024px;
      }
    }

    &-body{
      margin-top: 1rem;
      background: $white;
      border-radius: 0.5rem;

      &-edit{
        max-width: 70%;
      }
      &-goback{
        padding-top: 2.5rem;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
        margin-bottom: 2.5rem;
        font-family: 'SFPro-Text', sans-serif;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5;
        display: flex;
        align-items: center;
        color: $black;
        cursor: pointer;
        &-text{
          margin-left: 7px;
        }
      }
      &-box{
        padding: 2rem 2.5rem;
        @include on-medium-device{
          padding: 1rem;
        }
      }

      &-buttons{
        display: flex;
        margin-top: 25px;
        justify-content: flex-start;
        & > .button__wrapper{
          margin-bottom: 0;
          margin-right: 16px;
        }
      }

      &-savebutton{
        display: flex;
        margin-top: 2.5rem;
        justify-content: flex-end;
        & > .button__wrapper{
          margin-bottom: 0;
        }
      }

      &-info{
        &-section-title{
          font-family: 'SFPro-Text', sans-serif;
          font-weight: bold;
          font-size: 1.125rem;
          line-height: 24px;
          letter-spacing: -0.4px;
          color: $darkest-gray;
          text-align: left;
          padding-top: 32px;
        }

        &-description {
          margin-top: 16px;
          border: 1px solid $lighter-gray;
          border-radius: 6px;
          box-sizing: border-box;
          padding: 30px;
          text-align: justify;
          font-family: 'SFPro-Text', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 1.125rem;
          line-height: 1.44;
        }

        &-uploads{
          margin-top: 15px;
        }
      }
    }
  }
}
