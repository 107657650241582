.button__wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  font-family: 'SFPro-Text', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  padding: 0.5rem 0.875rem;

  &--large{
    width: 100%;
  }

  &--medium{
    width: 9.125rem;
  }

  &--primary{
    background-color: $main-green;
    color: white;
    &:hover{
      background-color: $light-green;
    }
    &:focus{
      border: 3px solid $darker-gray;
    }

    &-static{
      background-color: $main-green;
      color: white;
      cursor: pointer;
    }

    &-disabled{
      background-color: $lighter-gray;
      color: white;
      cursor: not-allowed;
    }
  }

  &--secondary{
    background-color: $white;
    border: 1px solid $light-gray;
    color: $darker-gray;
    &:hover{
      border: 1px solid $darker-gray;
    }
    &:focus{
      background-color: $lighter-gray;
      border: 3px solid $darker-gray;
    }

    &-static{
      background-color: $white;
      color: $darker-gray;
      cursor: pointer;
    }

    &-disabled{
      background-color: $white;
      color: $light-gray;
      cursor: not-allowed;
    }
  }

  &--warning{
    background-color: $system-red;
    color: white;
    &:hover{
      background-color: $system-red-dark;
    }
    &:focus{
      border: 3px solid $darker-gray;
    }

    &-static{
      background-color: $main-green;
      color: white;
      cursor: pointer;
    }

    &-disabled{
      background-color: $lighter-gray;
      color: white;
      cursor: not-allowed;
    }
  }

  &--ghost {
    box-shadow: none;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: 500;

    &:hover{
      color: $light-green;
    }
  }
}
