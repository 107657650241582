.filters-panel{

  &__wrapper{
    background-color: $white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;

    &--hidden{
      display: none;
      -webkit-transition: display 0.2s ease-out;
      -moz-transition: display 0.2s ease-out;
      -ms-transition: display 0.2s ease-out;
      -o-transition: display 0.2s ease-out;
      transition: display 0.2s ease-out;
    }
  }

  &__container{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  &__navigation{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-top: 16px;
    margin-bottom: 24px;
  }

  &__title{
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    font-size: 1.375rem;
    line-height: 1.1;
    color: $black;
  }

  &__toggle{
    cursor: pointer;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 1.5rem;
    height: 3.5rem;
    border-top: 1px solid $lighter-gray;
    border-left: 1px solid $lighter-gray;
    border-bottom: 1px solid $lighter-gray;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    & i.icon {
      line-height: 1;
      margin-right: 0 !important;
      z-index: 0;
    }
  }

  &__filters{

    &-startdate,
    &-enddate {
      margin-bottom: 10px;
    }

    &-list{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: calc(100vh - 155px);
      overflow: hidden;
      overflow-y: auto;

      &__header{
        font-family: 'SFPro-Text', sans-serif;
        width: 100%;
        font-size: 0.9rem;
        line-height: 1.25;
        color: $medium-gray;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 0.625rem 1.125rem;
        border-bottom: 1px solid $lighter-gray;

        &--iconcontainer{
          display: flex;
        }

        &--buttoncontainer {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          & > .button__wrapper{
            font-size: 0.9rem;
            margin-bottom: 0;
            padding: 0.25rem 0.625rem;
          }
        }

        &--icon{
          background-repeat: no-repeat;
          background-size: contain;
          width: 1rem;
          height: 1rem;
          background-image: url('../../../assets/images/filter_icon.svg');
          margin-right: 0.75rem;
        }
      }

      .select-box:last-child { margin-bottom: 0; }
    }

  }

  &__filter-options{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0 1.1rem;
    z-index: 1;
  }
}

.folders__container{
  width: 100%;
  padding: .75em 1em;
  border-top: 1px solid rgba(34,36,38,.15);
  flex: 0;
  height: 100%;
}
.folders__header{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'SFPro-Text', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: $black;
  cursor: unset;
  margin-bottom: 16px;
  position: relative;
  &__icons{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__ellipsis{
    margin-right: 8px !important;
  }
  &__ellipsis, &__arrow{
    height: 1.75rem !important;
    width: 1.75rem !important;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: center;
    &:hover{
      background-color: $lighter-gray;
      border-radius: 0.25rem;
    }
  }

}
.folders__menu{
  display: flex;
  width: 100%;
  position: absolute;
  top: 25px;
  background-color: $white;
  height: 50px;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  z-index: 3;
  &__add-folder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: $black;
    font-size: 0.875rem;
    line-height: 1.7;
    padding: 6px;
    cursor: pointer;
    font-family: 'SFPro-Text', sans-serif;
    &:hover{
      background-color: $lighter-gray;
      border-radius: 2px;
    }
    &-input{
      width: 100%;
      border-radius: 6px;
      border: 1px solid #E0E0E0;
      outline: none;
      box-sizing: border-box;
      font-family: 'SFPro-Text', sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.7;
      padding: 14px 8px;
      margin-bottom: 0;
      height: 26px;
    }
    &-input-actions{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.folders__body{
  &__menu-open{
    margin-top: 60px;
  }
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.folder-create-modal{
  width: 675px !important;
  padding-left: 60px;
  padding-right: 60px;
  @include on-tablet {
    width: 80% !important;
  }
}
