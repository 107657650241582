.report-categories{
  &-container{
    display: table;
    padding-bottom: 15px;
  }
  &-element{
    display: table-row;
    height: 47px;
    border-top: 1px solid $lighter-gray;

    &:last-child{
      border-bottom: 1px solid $lighter-gray;
    }

    &-title, &-value, &-separator, &-placeholder{
      text-align: left;
      font-family: 'SFPro-Text', sans-serif;
      font-size: 1rem;
      line-height: 1.5;
    }

    &-title {
      width: 25%;
      font-weight: bold;
      color: $darkest-gray;
      display: table-cell;
      padding-right: 1rem;
    }

    &-values {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &-separator{
      color: $medium-gray;
    }

    &-placeholder{
      color: $medium-gray;
      text-align: left;
    }

    &-value{
      text-align: center;
      color: $darker-gray;
      padding-left: 0.625em;
      padding-right: 0.625em;
      min-width: 6.25em;
      height: 2em;
      align-items: center;
      font-size: 1rem;
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      justify-content: center;
      border-radius: 0.25em;
      display: flex;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
    }

    &-select {
      & > .select-box {
        margin-bottom: 32px;
      }
    }
  }
}
