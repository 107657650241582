.analytics{
  &__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  &__bar-chart{
    padding: 0 30px 30px 30px;
  }

  &__pie-container{
    background-color: $background-lightgray;
    border-radius: 8px;

    &--chart{
      padding: 0 45px 45px 45px;
    }
  }


  &__map-container{
    background-color: $background-lightgray;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    > div:last-child{
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &__map-header{
    background-color: $background-lightgray;
    border-radius: 8px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 24px;
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.33;
    color: $darker-gray;
  }

  &__content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--scroll{
      height: calc(100vh - 136px);
      overflow-y: scroll;
      padding: 40px 80px 80px 80px;
      width: 100%;
      @include on-medium-device{
        padding: 40px;
      }
    }
  }

  &__header{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.375rem;
    line-height: 1.1;
    color: $darker-gray;
    margin-bottom: 44px;

    & > .select-box{
      width: unset;
    }
  }

  &__focuses{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 32px;

    &-container{
      width: 32%;
      border-radius: 8px;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 24px;
    }

    &-message{
      font-family: 'SFPro-Text', sans-serif;
      font-size: 1rem;
      line-height: 1.5;
      color: $darker-gray;
      text-align: left;
    }

    &-icon{
      width: 24px;
      height: 24px;
      margin-right: 32px;
    }
  }

  &__submissions{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;

    & > div:first-child{
      width: calc(68% - 26px);
      margin-right: 26px;
      background-color: $background-lightgray;
      border-radius: 8px;
    }
    & > div:last-child{
      width: 32%;
    }
  }

  &__panel{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: $background-lightgray;
    border-radius: 8px;

    &-header{
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.33;
      letter-spacing: -0.4px;
      color: $darker-gray;
      padding: 24px;
      text-align: left;
    }

    &-element{
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 24px 24px 16px 24px;
      border-top: 1px solid $lighter-gray;

      &--left{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }

      &--right{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
      }

      &-value{
        font-family: 'SFPro-Text', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.375rem;
        line-height: 1.1;
        color: $darker-gray;
      }

      &-type{
        font-family: 'SFPro-Text', sans-serif;
        font-style: normal;
        font-weight: normal;
        text-align: left;
        font-size: 1rem;
        line-height: 1.5;
        color: $darker-gray;
      }

      &-percentage{
        font-family: 'SFPro-Text', sans-serif;
        font-style: normal;
        font-weight: normal;
        text-align: right;
        font-size: 1rem;
        line-height: 1.5;
        color: $medium-gray;

        &--positive{
          color: $light-green;
        }
      }
    }
  }

  &__reports{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    &-element{
      width: 23%;
      height: 168px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 24px;
      background-color: $background-lightgray;
      border-radius: 8px;
      @include on-medium-device{
        padding: 20px;
      }

      &-sector{
        font-family: 'SFPro-Text', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.125rem;
        text-align: left;
        line-height: 1.33;
        letter-spacing: -0.4px;
        color: $darker-gray;
      }

      &-value{
        font-family: 'SFPro-Text', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 2.0625rem;
        line-height: 1;
        letter-spacing: -0.02em;
        color: $black;
      }
    }
  }

  &__maps{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;

    & > div:first-child{
      width: 66%;
      margin-right: 32px;
    }
    & > div:last-child{
      width: 31%;
    }
  }

  &__table{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: $background-lightgray;
    border-radius: 8px;
    padding: 24px;

    &-title{
      width: 100%;
      display: flex;
      flex-direction: row;
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.33;
      color: $darker-gray;
      margin-bottom: 29px;
    }

    &-header{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;

      & > div:first-child{
        width: 60%;
      }

      &-element{
        font-family: 'SFPro-Text', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        text-align: left;
        line-height: 1.5;
        color: $medium-gray;
        text-transform: uppercase;
      }
    }

    &-element{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 16px 8px;
      border-top: 1px solid $lighter-gray;

      & > div:first-child{
        width: 60%;
      }

      &-text{
        font-family: 'SFPro-Text', sans-serif;
        font-style: normal;
        text-align: left;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5;
        color: $darker-gray;
      }
    }
  }
}
