.container{
  &__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 95px;
    padding-right: 95px;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;

    @include on-medium-device{
      padding-left: 50px;
      padding-right: 50px;
    }
  }


  &__logo{
    background-image: url('../../../assets/images/timby_logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-top: 48px;
    min-width: 150px;
    min-height: 70px;
  }

  &__body{
    flex: 1;
    width: 100%;
    margin-top: -90px;
    @include on-medium-device{
      margin-top: 0;
    }
  }
}
