.admin-languages {

  .message-form-userselect{
    padding-top: 0;
  }

  &-buttons-container{
    display: flex;
    flex-direction: row;
    & > .button__wrapper{
      margin-bottom: 0;
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.admin-terminology-box {

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;

    &-title {
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5;
      color: $darker-gray;
    }

    .reset-link{
      color: #16675D;
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5;
      &:hover{
        text-decoration: underline;
        cursor: pointer;
      }
    }

  }

}
