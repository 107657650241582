.report-view-map{
  &-header{
    padding-bottom: 16px;
    border-bottom: 1px solid $lighter-gray
  }
  &-inputs{
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    & > .text-input__wrapper{
      flex: 1;
      margin-right: 16px;
      &:last-child{
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }
  &-body{
    padding-top: 24px;
    &-coordinates{
      display: flex;
      justify-content: flex-start;

      &-title, &-label{
        font-size: 1rem;
        line-height: 1.5;
        font-family: 'SFPro-Text', sans-serif;
        color: $darkest-gray;
        text-align: left;
        margin-right: 1.5rem;
      }
      &-title{
        font-weight: bold;
      }
      &-label-copyclick{
        svg{
          fill: $main-green;
          margin-right: 6px;
          margin-top: -2px;
        }
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5;
        color: $main-green;
        cursor: pointer;
        margin-top: 12px;
        display: flex;
        align-items: center;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
