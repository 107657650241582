.collapsible{
  &__header{
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &__text {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &:hover {
      > .collapsible__actions {
        display: flex;
      }
    }
    &-active{
      & > .collapsible__actions {
        display: flex !important;
      }
    }
  }


  &__actions{
    display: none;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    top: 0;
    right: 0.5rem;
    > * { margin-left: 0.5rem; }
  }

  &__wrapper{
    border-radius: 0 !important;
    box-shadow: none !important;
    width: 100% !important;

    &--bordered{
      border: 1px solid $lighter-gray !important;
      box-sizing: border-box !important;
      border-radius: 0.5rem !important;
    }

    & .title{
      display: flex;
      align-items: center;
      font-family: 'SFPro-Text' !important;
      font-weight: bold !important;
      font-size: 1rem !important;
      line-height: 1.5;
      color: $black !important;
      padding-right: 0.625em !important;
      // cursor: unset !important; // why would you do this?

      & i {
        margin-right: 0.5rem;
        font-size: 0.8125rem;
        transition: transform 0.1s;
        transform: translate(0, -0.175rem);
      }

      &.active {
        & i {
          transform: translate(0.325rem, -0.125rem) rotate(90deg);
        }
      }
    }
  }
}
