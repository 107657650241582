.fullImage{

  &__wrapper{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: relative;
  }


  &__message, &__author{
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1.125rem;
    text-align: left;
    line-height: 1.33;
    color: $white;
  }

  &__message{
    margin-bottom: 36px;
  }

  &__quote{
    display: flex;
    align-items: flex-start;
    position: absolute;
    bottom: 47px;
    left: 47px;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 504px;
  }
}
