.admin_tag_element{
  &-linecontainer{
    padding-left: 24px;
    align-items: center;
    display: flex;
    height: 56px;
    justify-content: space-between;
    border-top: 1px solid $lighter-gray;
    &:hover{
      .admin_tag_element-linecontainer__icons{
        display: flex;
      }
    }
    &:first-child{
      border-top: none !important;
    }
    &-editmode{
      height: auto;
      align-items: flex-start;
      flex-direction: column;
      .admin_tag_element-linecontainer__icons{
        display: flex !important;
      }
    }
    &-info{
      width: 100%;
      height: 56px;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &__text {
      color: $black;
      font-family: 'SFPro-Text', sans-serif;
      font-size: 1rem;
    }
    &__icons {
      display: none;
      .round-icon_wrapper:first-child{
        margin-left: 8px;
      }
      .rounded-icon_wrapper{
        margin-right: 8px;
      }
    }
  }
  &-form{
    &__wrapper{
      width: 100%;
      //height: 192px;
      padding-right: 26px;
      padding-top: 18px;
    }
    &__input-container{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      & > .text-input__wrapper{
        margin-right: 16px;
        width: 351px;
      }
    }
    &__buttons{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-right: 26px;
      padding-bottom: 30px;
      & > .button__wrapper {
        margin-bottom: 0;
      }
    }
  }
}
