.dashboard-container{

  &__wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__navigation{
    height: 100%;
    width: 64px;
  }
}
