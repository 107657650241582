.story-element {
  &-container {
    max-height: 506px;
    min-height: 520px;
    min-width: 384px;
    max-width: 384px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-body {
    padding: 40px;
    flex: 1,
  }

  &-title {
    color: $black;
    font-family: 'SFPro-Text', sans-serif;
    text-align: left;
    font-size: 1.75rem;
    line-height: 1.25;
    font-weight: 400;
  }
  &-subtitle {
    padding-top: 2.75rem;
    font-size: 1.125rem;
    font-family: 'SFPro-Text', sans-serif;
    text-align: left;
    font-weight: 400;
    line-height: 1.33;
    color: $darkest-gray;
  }
  &-footer {
    border-top: 1px solid rgba($darkest-gray, 0.3);
    padding-left: 40px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-status{
      &-text {
        color: $darkest-gray;
        font-family: 'SFPro-Text', sans-serif;
        font-size: 1.125rem;
        letter-spacing: -0.5px;
        font-weight: bold;
        text-align: left;
      }
      &-date {
        color: $darkest-gray;
        font-family: 'SFPro-Text', sans-serif;
        font-size: 1rem;
        text-align: left;
      }
    }
    &-actions{
      display: flex;
      justify-content: flex-end;
      & > .rounded-icon_wrapper {
        margin-right: 8px;
        z-index: 3;
      }
      & > .rounded-icon_wrapper:last-child{
        margin-right: 0;
      }
    }
  }
}
