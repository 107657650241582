.select-box{
  width: 100%;
  margin-bottom: 1.25rem;
  text-align: left;

  .select-box__wrapper,
  .ui.dropdown {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 3.25em !important;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    color: $darker-gray;
    border-radius: 6px;
    border: 1px solid $lighter-gray;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
    margin-bottom: 0.375rem;
    padding: 0.5rem 1rem !important;
    box-sizing: border-box;

    &--medium{ width: 256px !important; }
    &--small{ width: 214px !important; }
    &--errors{ border: 1px solid $system-red !important; }

    &.ui.selection.dropdown > .icon {
      margin-left: auto;
    }

    &.ui.multiple.dropdown > .label {
      font-size: inherit;
    }

    & > .text {
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 2em);
      white-space: nowrap;
      margin: auto 0 !important;
    }

    & > .clear {
      position: relative;
      z-index: 999;
    }
  }
}

.select-box__wrapper {
  &.ui.selection.active.dropdown,
  &.ui.selection.active.dropdown:hover,
  &.ui.selection.active.dropdown:focus,
  &.ui.selection.dropdown:focus,
  &.ui.selection.dropdown:hover,
  &.ui.selection.active.dropdown .menu,
  &.ui.selection.active.dropdown:hover .menu {
    border-color: $main-green;
  }
}

.ui.default.dropdown:not(.button)>.text,
.ui.dropdown:not(.button)>.default.text,
.ui.selection.dropdown{
  font-family: 'SFPro-Text', sans-serif;
  font-weight: normal;
  font-size: 1rem !important;
  color: $smooth-gray;
}
