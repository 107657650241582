.navigation-bar{

  &__wrapper{
    width: 100%;
    height: 100%;
    background-color: $darker-green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__logo{
    background-image: url('../../../assets/images/timby_nav_icon.svg');
    background-repeat: no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__options{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__option{
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    &-logout{
      margin-bottom: 1.25rem;
    }
    &-icon{
      opacity: 0.5;
      width: 1.5rem;
      height: 1.5rem;
      stroke: $white;
      &--selected{
        opacity: 1;
      }
    }
    &-logout-icon{
      fill: $white;
      margin-left: -0.25rem;
    }
    &:hover{
      border-radius: 6px;
      background-color: rgba($color: $white, $alpha: 0.1);
      fill: $white;
      svg {
        opacity: 1;
      }
    }
    &--selected{
      border-radius: 6px;
      background-color: rgba($color: $white, $alpha: 0.1);
      cursor: default;
    }

  }

  &__bottom-section{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__language{
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $lightest-gray;
    border: 1px solid rgba($color: $white, $alpha: 0.2);
    border-radius: 8px;
    padding: 0.25em 0.75em;
    margin-bottom: 0.75rem;
    text-transform: capitalize;
    &:hover{
      background-color: rgba($color: $white, $alpha: 0.2);
    }

    &-description{
      margin-bottom: 2px;
    }
  }

  &__profile{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: $profile-blue;
    margin-bottom: 1.325rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5;
    color: $white;
    cursor: pointer;
    &:hover{
      background-color: $profile-dark-blue
    }
  }
}

.navigation-bar__option[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
.navigation-bar__option[data-title]:after {
  content: attr(data-title);
  background-color: $darker-green;
  color: $white;
  font-family: 'SFPro-Text', sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  padding: 4px 5px 4px 5px;
  position: absolute;
  left: 0;
  border-radius: 2px;
  top: 101%;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
