.story-report{
  &-container {
    border: 1px solid $lighter-gray;
    border-radius: 8px;
    box-sizing: border-box;
    padding-left: 32px;
    padding-bottom: 25px;
    padding-right: 32px;
    padding-top: 25px;
    position: relative;
    margin-bottom: 16px;
  }
  &-title{
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 1.25;
    color: $darkest-gray;
    text-align: left;
  }
  &-edit{
    width: 40px;
    height: 40px;
    position: absolute;
    right: 7.5px;
    top: 12px,
  }
  &-info{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 25px;
    padding-bottom: 25px;
    &-date{
      margin-right: 25px;
      display: flex;
      align-items: center;
      &-icon{
        margin-top: -3px;
        fill: $medium-gray;
        margin-right: 5px;
      }
    }
    &-date, &-reporter {
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5;
      font-family: 'SFPro-Text', sans-serif;
      color: $medium-gray;
    }

  }
  &-body{
    text-align:left;
    height: 72px;
    overflow: hidden;
    position: relative;
    &-gradient{
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 93.27%);
      height: 72px;
      width: 100%;
      z-index: 22;
      position: absolute;
    }
    &-open {
      height: auto;
      min-height: 72px;
    }
    &-description {
      font-style: normal;
      font-weight: normal;
      text-align: left;
      font-size: 1rem;
      line-height: 1.5;
      color: $black;
      width: 100%;
    }
  }
  &-opencontainer{
    margin-top: 32px;
    color: $main-green;
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
    line-height: 1.5;
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
