.react-datepicker{
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.375rem !important;
  border: none !important;
  font-family: 'SFPro-Text' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 0.875rem !important;
  line-height: 1.1 !important;
  text-align: center !important;
  width: 280px;
  height: 300px;

  &__close-icon{
    &:after{
      background-color: transparent !important;
      color: $light-green !important;
      padding: 0 0.75rem 0 0 !important;
      font-size: 1.5rem !important;
    }
  }
  &-popper {
    z-index: 90;
  }
}

.react-datepicker{
  &-wrapper{
    width: 100%;
  }

  &-wrapper > div{
    height: 3.325rem !important;
  }
}

.react-datepicker__day--keyboard-selected, .react-datepicker__day--selected{
  background-color: transparent !important;
  border-radius: unset !important;
  color: $black !important;
}

.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range{
  background-color: $light-green !important;
  border-radius: unset !important;
  color: $white !important;
}

.react-datepicker__day{
  color: $darker-gray;
  height: 2rem !important;
  width: 2rem !important;
  line-height: 2rem !important;

}

.react-datepicker__triangle{
  display: none;
}

.react-datepicker__header {
  height: 96px !important;
  background-color: $white !important;
  border-bottom: unset !important;
  padding-top: 24px !important;
}

.react-datepicker__month{
  height: 192px !important;
  padding: 0 8px !important;
  margin-top: 0 !important;
}
.react-datepicker__week{
  height: 32px !important;
  width: 100% !important;
}

.react-datepicker__month-container{
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.react-datepicker__day-names{
  font-family: 'SFPro-Text' !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  text-align: center !important;
  color: $brown1 !important;
  opacity: 0.6 !important;
  padding: 0.75rem 0.5rem 0 0.5rem !important;

}

.react-datepicker__day-name{
  width: 34px !important;
  height: 16px !important;
}

.react-datepicker__current-month{
  font-family: 'SFPro-Text' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  text-align: center !important;
  color: $brown1 !important;
}

.react-datepicker__navigation{
  top: 16px !important;
  border: unset !important;
}

.react-datepicker__navigation--previous{
  left: 24px !important;
  width: 32px !important;
  height: 40px !important;
  background-color: $lightest-gray !important;
  border-radius: 6px !important;
  background-image: url('../../../assets/images/down_caret.svg') !important;
  background-size: 50% !important;
  background-repeat: no-repeat !important;
  background-position: center !important ;
  transform: rotate(90deg) !important;
}

.react-datepicker__navigation--next{
  right: 24px !important;
  width: 32px !important;
  height: 40px !important;
  background-color: $lightest-gray !important;
  border-radius: 6px !important;
  background-image: url('../../../assets/images/down_caret.svg') !important;
  background-size: 50% !important;
  background-repeat: no-repeat !important;
  background-position: center !important ;
  transform: rotate(-90deg) !important;
}

.custom-datepicker-date{
  &-selected{
    background-color: $light-green !important;
    border-radius: unset !important;
    color: $white !important;
  }
}
