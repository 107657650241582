.admin-layer-content{
  &__wrapper{
    width: 100%;
    flex-direction: column;
    display: flex;
    padding: 3.75rem 2.5rem;

    &--create{
      padding: 0 2.5rem;
    }
  }

  &__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;

    & > * {
      width: calc(50% - 15px);
    }

    & input[type="color"]{
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.25rem;
    }
  }


  /*
  &__left, &__right{
    width: calc(50% - 15px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__right{
    &__status {
      margin-top: 20px;
    }
  }
  */

  &__radio-group{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;
    margin-bottom: 0.5rem;

    &__title {
      display: block;
      width: 100%;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    & > .custom-radio__wrapper{
      margin-right: 30px;
    }
  }
  &__translations{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    & > .text-input__wrapper{
      width: calc(50% - 15px);
    }
  }

  &__header{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    &-title{
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.375rem;
      line-height: 1.1;
      color: $black;
    }
  }

  &__actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 300px;

    & > .button__wrapper {
      margin-right: 34px;
      margin-bottom: 0;
    }

    &--create{
      width: 200px;
    }
  }
}

.layer-drop{

  &__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    border-radius: 12px;
    border: 1px dashed $lighter-gray;

    &-errors{
      border: 1px dashed $system-red;
    }

    &:focus{
      outline: unset;
    }
  }

  &__header{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 6px;
    color: $dark-gray;
  }

  &__subheader{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 19px;
    color: $medium-gray;
  }
}
