.image-modal__wrapper{
  &-image__only{
    width: 90% !important;
    height: 90% !important;
    max-width: 650px;
    max-height: 600px;
    max-width: 100%;
    max-height: 100%;
  }
  //width: 70% !important;
  //height: 70%;
  max-width: 650px;
  max-height: 600px;
  padding: 1.5rem;
  padding-top: 2.5rem;
  .content{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.admin-modal{

  &__wrapper__mini {
    width: 700px !important;
    @include on-tablet {
      width: 80% !important;
    }
  }

  &__header{
    height: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 2.0625rem;
    line-height: 1;
    color: $black;
  }

  &__subtitle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'SFPro-Text', sans-serif;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.4;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    color: $black;
  }

  &__actions{
    height: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > .button__wrapper {
      margin-right: 20px;
      margin-left: 20px;
      height: 48px;
      width: 160px;
    }
  }
}

.ui.modal>.close{
  right: 8px !important;
  top: 8px !important;
  color: $black !important;
  border-radius: 50% !important;
  background-color: $white !important;
  cursor: pointer;
  width: 48px !important;
  height: 48px !important;
  background-image: url('../../../assets/images/close.svg');
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  &:hover{
    background-color: $lightest-gray !important;
  }

  &::before{
    content: "";
  }
}
