.split-view{

  &__wrapper{
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: white;
  }

  &__left{
    display: none;
  }

  &__right {
    display: flex;
    height: 100%;
    width: 100%;
  }

  @include respond-to(tablet) {
    &__left {
      display: flex;
      width: 60%;
      height: 100%;
    }

    &__right{
      width: 40%;
    }
  }

}
