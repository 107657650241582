.folder-element{
  &__wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 7px;
    margin-top: 7px;
    cursor: pointer;
    &-selected{
      background-color: $background-lightgreen;
      border-radius: 4px;
      &:hover{
        background-color: $background-lightgreen;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
    &:hover{
      background-color: $lightest-gray;
      border-radius: 4px;
    }
  }

  &__title{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    color: $darker-gray;
    margin-left: 16px;
    margin-right: 16px;
    text-align: left;
    flex: 1;
  }

  &__icon {
    fill: inherit;
  }
}
