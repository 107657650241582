.admin-container {
  &__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 40px 80px;
    width: 100%;
    height: calc(100vh - 136px - 55px);
    overflow-y: scroll;
  }

  &-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 32px;
    border-bottom: 1px solid $lighter-gray;
    margin-bottom: 22px;

    &-title{
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.375rem;
      line-height: 1.1;
      margin: 0;
      color: $black;
    }

    &-buttons{
      display: flex;
      flex-direction: row;

      & > .button__wrapper{
        margin-bottom: 0;
        margin-right: 10px;
        padding-left: 30px;
        padding-right: 30px;
        width: auto;

        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

  &-body {

  }
}
