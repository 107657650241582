.toggle__wrapper{
  font-family: 'SFPro-Text', sans-serif;
  font-weight: normal;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &--vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .toggle__label {
      font-weight: bold;
      text-align: left;
      font-size: 1rem;
      line-height: 1.5;
      align-items: flex-start;
      color: $darker-gray;
      margin-bottom: 0.5rem;
    }
  }
}
.toggle__label{
  &-inverse {
    margin-left: 8px;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    color: $darkest-gray;
  }
}

.ui.checked.fitted.toggle.checkbox.toggle__element > label:before
{
  background-color: $lighter-green !important;
}
