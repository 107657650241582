.report-urgency-bar{

  &-alert{
    height: 40px;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    svg {
      margin-top: -1px;
      margin-right: 5px;
    }
    &-critical, &-normal, &-low{
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5;
    }
    &-critical{
      background-color: $system-red;
      color: $white;
      fill: $white;
    }
    &-normal{
      background-color: $system-orange;
      color: $darkest-gray;
      fill: $darkest-gray;
    }
    &-low{
      background-color: $system-yellow;
      color: $darkest-gray;
      fill: $darkest-gray;
    }
  }
}
