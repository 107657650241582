.message-form {
  width: 50%;
  padding-right: 16px;
  @include on-medium-device{
    width: 100%;
    padding-right: 0;
  }
  & > .select-box__wrapper {
   max-width: 100%;
  }


  &-textarea{
    max-width: 100%;
    min-width: 100%;
    min-height: 248px;
    padding: 22px 24px;
    display: flex;
    justify-content: flex-start;
    border: 1px solid $lighter-gray;
    border-radius: 6px;
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1rem;
    color: $darkest-gray;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    margin-bottom: 8px;

    &-box{
      text-align: left;
      width: 100%;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &::placeholder {
      color: $medium-gray;
      font-size: 1rem;
    }
    &:focus{
      border: 1px solid $main-green;
    }

    &-error{
      border: 1px solid $system-red;
      &:focus{
        border: 1px solid $system-red;
      }
    }
  }
  &-radiogroup {
    padding-top: 32px;

    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    & > .custom-radio__wrapper label {
        //line-height: 28px !important;
        &::before{
          top: -1px !important;
        }
        &::after{
          top: -1px !important;
        }
    }
    & > .custom-radio__wrapper{
      padding-right: 100px;
      @include on-medium-device {
        padding-right: 50px;
      }
    }
    & > .custom-radio__wrapper:last-child{
      padding-right: 0;
    }
  }
  &-button {
    width: 146px;
    padding-top: 20px;
  }
}
.message-form-textarea:focus-visible{
  border: 1px solid $main-green !important;
  outline: none;
}

.message-form-textarea-error:focus-visible{
  border: 1px solid $system-red !important;
  outline: none;
}
