.admin_tagsgroups{
  &_container{
    padding-top: 50px;
    &:last-child{
      padding-bottom: 50px;
    }
  }
  &_title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-bottom: 16px;

    &__text{
      font-size: 1rem;
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      color: $black;
    }
    &__icons{
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      .rounded-icon_wrapper{
        margin-left: 4px;
        margin-right: 4px;
      }
      .rounded-icon_wrapper:last-child{
        margin-right: 0;
      }
    }
  }

  &_content-box{
    width: 100%;
    border: 1px solid $lighter-gray;
    border-radius: 8px;
    overflow: hidden;

    &-addcontainer{
      border-width: 2px;
      border-style: dashed;
      border-color: $light-gray;
      height: 56px;
      border-radius: 8px;
      margin-right: 14px;
      margin-left: 24px;
      margin-bottom: 24px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &-addtext{
      font-size: 1rem;
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      color: $light-gray;
    }
  }
}
