.story{
  &-header{
    &-createbutton {
      & > .button__wrapper{
        width: initial !important;
        min-width: 160px;
        height: 40px;
      }
    }
  }

  &-container{
  width: 100%;

    &__wrapper {
      width: 100%;
      flex-wrap: wrap;
      height: calc(100vh - 136px);
      overflow-y: scroll;

      &-search {
        padding-left: 80px;
        padding-right: 80px;
        height: 72px;
        border-bottom: 1px solid $lighter-gray;
        display: flex;
        align-items: center;
        @include on-medium-device{
          padding-left: 40px;
          padding-right: 40px;
        }

        & > .search-bar__wrapper {
          width: 300px !important;
        }
      }
      &-elements{
        padding-left: 64px;
        padding-right: 64px;
        padding-top: 45px;
        padding-bottom: 45px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @include on-medium-device{
          padding-left: 24px;
          padding-right: 24px;
        }

        & >.story-element-container{
          margin-left: 16px;
          margin-right: 16px;
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.c1 { background-color: $timby-blue-light; }
.c2 { background-color: $timby-green-light; }
.c3 { background-color: $timby-orange-light; }
.c4 { background-color: $timby-red-light; }
.c5 { background-color: $timby-yellow-light; }
