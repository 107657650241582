.loading__wrapper{
  background-color:rgba($color: $background-lightgreen, $alpha: 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1003;

  & .ui.loader:before, .ui.loader:after{
    width: 105px;
    height: 105px;
    border-width: 5px;
  }

  & .ui.loader:after{
    border-color: $light-green transparent transparent;
  }
}
