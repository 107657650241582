$timby-yellow: #F2C94C;
$timby-orange: #EA9B3F;
$timby-red: #D34718;
$timby-blue: #0B5BAE;
$timby-green: #1B876D;

$timby-yellow-light: #fcf4db;
$timby-orange-light: #f5d1a6;
$timby-red-light: #fadfd6;
$timby-blue-light: #c9e2fc;
$timby-green-light: #dcf8f1;

$white: #FFFFFF;
$black: #000000;

$darkest-gray: #111111;
$darker-gray: #333333;
$dark-gray: #4F4F4F;
$medium-gray: #828282;
$smooth-gray: #BBBBBB;
$light-gray: #BDBDBD;
$lighter-gray: #E0E0E0;
$lightest-gray: #F2F2F2;

$brown1: #3B3D3E;
$main-green: #1B8760;
$darker-green: #2B424A;
$lighter-green: #6FCF97;
$light-green: #10714E;
$text-green: #219653;
$system-orange: #F2994A;
$system-yellow: $timby-yellow;
$system-red: #EB5757;
$system-red-dark: #E62929;
$background-darkgreen: #2B424A;
$background-lightgreen: #F2F8F7;
$background-lightblue: #edf5fe;
$background-lightgray: #F8F8F8;
$profile-blue: #2B8CBE;
$profile-dark-blue: #2b7abe;
