.notfound{
  &-body{
    background: $background-lightgreen;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
  }
  &-content{
    display: flex;
    flex-direction: column;
    margin-left: 300px;
    @include on-medium-device{
      margin-left: 50px;
    }

    & > .button__wrapper{
      width: 252px;
    }

  }
  &-icon{
    margin-bottom: 50px;
  }
  &-title{
    display: flex;
    align-items: center;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    font-size: 2.625rem;
    line-height: 1;
    letter-spacing: -0.02em;
    text-align: left;
    color: $black;
    margin-bottom: 32px;
  }
  &-subtitle{
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1.75rem;
    line-height: 1.25;
    display: flex;
    align-items: center;
    text-align: left;
    color: $black;
    margin-bottom: 38px;
  }
}
