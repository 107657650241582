.admin_tabbar.ui.menu{
  padding-left: 80px;
  height: 55px;
  margin-bottom: 0;
  margin-top: 0;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid $lighter-gray;

  .item{
    font-family: 'SFPro-Text', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    height: 100%;
    text-align: center;
    color: $black;
    border: 0;
    border-bottom: 2px solid transparent;
    position: relative; top: 2px;

    // borderless
    &:before, &:after { content: none; }

    &.active {
      border-radius: 0;
      background-color: transparent;
      border-bottom: 2px solid $main-green;
      font-weight: 700;
    }

    &.active:hover,
    &:hover {
      background-color: transparent;
    }
  }
}
