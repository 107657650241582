.custom {
  &-textarea{
    padding-bottom: 12px;
    padding-top: 16px;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid $lighter-gray;
    outline-color: transparent !important;
    border-radius: 6px;
    position: relative;
    &:focus-within{
      border: 1px solid $main-green;
    }
    &-edit{
      position: absolute;
      right: 7.5px;
      top: 7.5px;
    }
    &-input {
      width: 100%;
      resize: none;
      font-family: 'SFPro-Text', sans-serif;
      font-size: 1rem;
      line-height: 1.5;
      color: $black;
      border: none !important;

      box-shadow: none !important;
      background: inherit;
      &:focus {
        outline-color: transparent;
        border: none;
        box-shadow: none;
      }

      &::placeholder {
        color: $smooth-gray;
        opacity: 1;
      }
    }
  }
}

.custom-textarea-input:focus-visible{
  outline-color: transparent !important;
  outline: none;
  border: none;
  box-shadow: none !important;
}
