.search-bar__wrapper{
  flex-grow: 1;

  & > .ui.left.icon.input {
    width: 100% ;
  }

  &.ui.search .prompt {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: $lightest-gray;
    border: unset;
  }
}
