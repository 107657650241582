.report-zip{

  &__wrapper{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    color: $darker-gray;
    background-color: #F8F8F880;
    z-index: 99;
  }

  &__icon{
    margin-bottom: 30px;
    fill: $darker-gray;
  }

}
