.user-list-content{
  &__wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1;

    max-width: 70rem;
    &__create {
      max-width: unset;
    }

    & > .text-input__wrapper,
    & > .select-box,
    & > .toggle__wrapper {
      margin-bottom: 1.25rem;
    }

    & > .text-input__wrapper{
      width: 45%;
      padding-left: 40px;
      margin-right: 10px;
    }

    & > .select-box{
      width: 45%;
      padding-left: 40px;
      margin-right: 10px;
    }

    & > .toggle__wrapper {
      width: 45%;
      padding-left: 40px;
      margin-right: 10px;
      justify-content: flex-start;

      .toggle__label {
        margin-right: 34px;
      }
    }

  }

  &__actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 40px;

    & > .button__wrapper {
      width: 146px;
      margin: 0;
    }
  }

}
