.form-container{
  &__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title{
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    font-size: 2.0625rem;
    line-height: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $black;
    padding-bottom: 44px;
  }

  &__subtitle{
    font-family: 'SFPro-Text', sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5;
    color: $dark-gray;
    margin-top: -30px;
    padding-bottom: 40px;
  }


  &__validation{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -30px;
    padding-bottom: 40px;

    &--title{
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      text-align: left;
      font-size: 1.125rem;
      line-height: 1.33;
      color: $darker-gray;
      padding-bottom: 6px;
    }

    &--list{
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &--element{
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      padding-top: 8px;
    }

    &--icon{
      background-image: url('../../../assets/images/green-checkmark.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 16px;
      height: 16px;
      margin-right: 16px;
      &-error{
        background-image: url('../../../assets/images/red_warning.svg');
      }
    }

    &--value{
      font-family: 'SFPro-Text', sans-serif;
      font-weight: 400;
      text-align: left;
      font-size: 1rem;
      line-height: 1.5;
      color: $dark-gray;
    }
  }

  &__inputs{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__submit{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .button__wrapper {
      font-size: 1.1rem;
      padding: 0.75rem 1rem;
    }
  }
}

.twofactor{
  &-numberinput{
    margin-bottom: 16px !important;
  }

  &-checkbox{
    width: 100%;
    display: flex;
    margin-bottom: 30px;
  }
}
