.rounded-icon{
  &-svg {
    fill: $dark-gray;
  }
  &_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 2.75rem;
    height: 2.75rem;
    //box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    overflow: hidden;
    background-color: $white;

    &--small{
      width: 2rem;
      height: 2rem;
    }

    &--medium{
      width: 3rem;
      height: 3rem;
    }

    &--large{
      width: 3.5rem;
      height: 3.5rem;
    }

    &--border{
      border: 1px solid $lighter-gray;
    }

    &--hover:hover{
      background-color: $lightest-gray;
    }

    &--disabled{
      background-color: $lighter-gray;
      cursor: not-allowed;
    }
  }
}
