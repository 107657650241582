.tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.tag {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0.325rem 0.5rem;
  min-height: 1.5rem;
  min-width: 147px;
  max-width: 220px;
  font-size: 1rem;
  font-family: 'SFPro-Text', sans-serif;
  font-weight: bold;
  border-radius: 4px;
  margin: 0 0.75rem 0.75rem 0;
  & > span {
    width: 100%;
  }
  &-closeicon{
    cursor: pointer;
  }
}

.capitalize {
  text-transform: capitalize;
}

.message-notice {
  display: block;
  border: 1px solid $system-yellow;
  background-color: lighten($system-yellow, 30%);
  padding: 4px 8px; border-radius: 4px;
  font-style: italic;
}

.form-label {
  display: block;
  font-family: 'SFPro-Text', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  color: $darker-gray;
  margin-bottom: 0.5rem;
}
