.report-info{

  &__wrapper{
    width: calc(100% - 16px);
    display: flex;
    height: 6rem;
    min-height: 6rem;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: $white;
    padding: 8px; margin: 0 8px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0; left: 0; right: 0;
      height: 1px;
      background-color: $lighter-gray;
    }

    &:first-child,
    &--selected,
    &--selected + &,
    &:hover,
    &:hover + & {
      &:before {
        visibility: hidden;
      }
    }

    &:hover {
      background: $lightest-gray;
      border-radius: 8px;
      cursor: pointer;
    }

    &--selected, &--selected:hover {
      background: $background-lightgreen;
      border: 1px solid #AECEC7;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  &__main__wrapper{
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }

  &__status{
    height: 100%;
    padding: 4px 8px 4px 0;
    // width: 12%;
    display: flex;
    flex-direction: row;
  }

  &__verified{
    fill: $light-gray;
    &--check{
      fill: $lighter-green;
    }
  }

  &__new{
    width: 8px;
    height: 8px;
    background-color: $main-green;
    border-radius: 50%;
    margin-right: 9px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  &__info{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    color: $darker-gray;
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
    overflow: hidden;
    max-width: 98.5%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &__description {
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $medium-gray;
    text-align: left;
    overflow: hidden;
    max-width: 95%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__date-box{
    height: 100%;
    width: 13%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: 'SFPro-Text', sans-serif;
    font-size: 0.875rem;
    line-height: 1.25;
    color: $medium-gray;
    text-align: left;
  }

  &__date{
    margin-bottom: 8px;
  }

  &__urgent{
    fill: $system-orange;
  }

}
