.image-gallery-custom-right-nav-container,
.image-gallery-custom-left-nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.5rem; width: 3.75rem;

  z-index: 4;
  top: 50%;
  transform: translateY(-50%);

  .edit-mode & {
    margin-top: -3.25rem;
  }

  cursor: pointer;
  outline: none;
  position: absolute;

  border-radius: 0.5rem;
  transition: 0.5s;

  svg path {
    stroke: $lighter-gray;
    transition: stroke 0.2s;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    svg path { stroke: $dark-gray; }
  }
}

.image-gallery-custom-right-nav-container{ right: 1rem; }
.image-gallery-custom-left-nav-container{ left: 1rem; }

.custom-image-gallery{
  margin: 12px auto;

  .image-gallery-slide {
    width: 100%;
    box-sizing: content-box;
  }

  .image-gallery-image {
    width: initial;
    height: auto;
    object-fit: initial;
    border-radius: 0.5rem;

    max-width: 100%;
    max-height: 480px !important;
    @include respond-to(large) {
      max-height: 600px !important;
    }
  }

  .image-gallery-thumbnails {
    padding: 1rem 0;
  }

  .image-gallery-thumbnail{
    width: initial;
    & + & { margin-left: 0.75rem; }

    &-image {
      max-width: 160px;
      max-height: 120px;
      filter: grayscale(0.3);
      opacity: 0.7;
      z-index: 9;
      transition: transform 0.2s;
      cursor: pointer;
      border: 4px solid transparent;
      border-radius: 8px;
    }

    &.active {
      border-color: transparent;

      .image-gallery-thumbnail-image {
        filter: none;
        opacity: 1;
        transform: scale(1.1);
      }
    }

    &:hover, &:focus{
      border-color: transparent;
    }
  }

  .image-gallery-description{
    position: static;
    background: transparent;
    margin: 1rem 0 0;
    padding: 0;
    color: $darkest-gray;
    width: 100%;
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: left;

    .custom-image-description{
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 48px;

      &-svg {
        position: relative;
        top: 2px;
        margin: 0 10px 0 0;
      }
      &-title {
        margin: 0 10px 0 0;
      }
    }
  }
}

.image-modal {

  &__wrapper {
    // I really dislike how semantic ui puts !important everywhere and forces me to do the same.
    width: auto !important;
    height: auto !important;
    max-width: 90vw !important;
    max-height: calc(80vh + 5rem) !important;
    border-radius: 0.5rem !important;

    .content {
      background: transparent;
    }
  }

  &__image {
    max-width: 100%;
    max-height: 75vh;
    border-radius: 0.5rem;
  }
}
