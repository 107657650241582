.story-info-container{
  &-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 26px;
    padding-right: 16px;
    //height: 72px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 52px;
    &-goback{
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5;
      display: flex;
      align-items: center;
      color: $black;
      cursor: pointer;
      &-text{
        margin-left: 7px;
      }
    }
    &-buttons{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      & > .rounded-icon_wrapper{
        margin-right: 16px;
      }
      & > .button__wrapper{
        margin-bottom: 0;
      }
    }
  }
  &-body{
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    @include on-medium-device{
      padding-left: 40px;
      padding-right: 40px;
      flex-direction: column;
    }
    &-publishcontainer{
      width: 210px;
      margin-left: 50px;
      @include on-medium-device{
        width: 100%;
        margin-left: 0;
        display: flex;
        flex-direction: row;
      }
      &-title{
        font-family: 'SFPro-Text', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.33;
        text-align: left;
        letter-spacing: -0.4px;
        color: $darker-gray;
        margin: 0;
        padding-bottom: 10px;
      }
      &-publish{
        padding-bottom: 26px;
        @include on-medium-device{
          margin-right: 30px;
        }
        &-text{
          font-family: 'SFPro-Text', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.5;
          text-align: left;
          color: $medium-gray;
        }
      }
      &-status{
        & > .toggle__wrapper {
          justify-content: flex-start !important;
          align-items: flex-start !important;
        }
      }
    }
    &-main{
      flex: 1;
    }
  }

}
