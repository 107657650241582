.report-header{

  &__wrapper{
    display: flex;
    width: calc(100% - 32px);
    min-height: 40px;
    margin: 16px;
    position: relative;
    z-index: 1;

    &--map{
      position: absolute;
      top: 16px;
      left: 16px;
      margin: 0;
      z-index: 1;
    }

    &--nobuttons{
      width: 100%;
      & > .search-bar__wrapper {
        width: 100%;
      }
    }
  }

  &__group{
    width: 100%;
    max-width: 130px;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    color: $darker-gray;

    & > button{
      display: flex !important;
      padding:  12px 6px !important;
      max-width: 60px !important;
      height: 40px !important;
      border-radius: 6px !important;
      background-color: $white !important;
      border: 1px solid $lightest-gray !important;
      box-sizing: border-box !important;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
      color: $darker-gray !important;
      align-items: center !important;
    }
  }

  &__space{
    width: 8px;
  }

  &__list-icon, &__map-icon{
    margin-right: 5px;
  }
}

.ui.buttons.report-header__group > button.ui.active.toggle.button{
  display: flex !important;
  align-items: center !important;
  padding:  12px 6px !important;
  max-width: 60px !important;
  height: 40px !important;
  border-radius: 6px !important;
  background-color: $white !important;
  border: 1px solid $light-gray !important;
  box-sizing: border-box !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  color: $darker-gray !important;
}
