.reports-container{

  &__small-panel{
    height: 100%;
    width: 20%;
    max-width: 300px;
    -webkit-transition: width 0.1s ease-in;
    -moz-transition: width 0.1s ease-in;
    -ms-transition: width 0.1s ease-in;
    -o-transition: width 0.1s ease-in;
    transition: width 0.1s ease-in;
    border-right: 1px solid $lighter-gray;

    &--hidden{
      width: 0;
      -webkit-transition: width 0.1s ease-out;
      -moz-transition: width 0.1s ease-out;
      -ms-transition: width 0.1s ease-out;
      -o-transition: width 0.1s ease-out;
      transition: width 0.1s ease-out;
    }
  }

  &__mid-panel{
    background-color: $white;
    height: 100%;
    width: 33%;
    max-width: 480px;

    @media screen and (max-width: 1280px) {
      max-width: 320px;
    }

    -webkit-transition: width 0.1s ease-in;
    -moz-transition: width 0.1s ease-in;
    -ms-transition: width 0.1s ease-in;
    -o-transition: width 0.1s ease-in;
    transition: width 0.1s ease-in;

    &--hidden{
      width: 0;
      -webkit-transition: width 0.1s ease-out;
      -moz-transition: width 0.1s ease-out;
      -ms-transition: width 0.1s ease-out;
      -o-transition: width 0.1s ease-out;
      transition: width 0.1s ease-out;
    }
  }

  &__report{
    &-folder{
      border-top: 1px solid $lighter-gray;
      cursor: pointer;
      margin-left: 8px;
      margin-right: 8px;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.33;
      display: flex;
      align-items: center;
      letter-spacing: -0.4px;
      color: $darker-gray;
      max-height: 45px;
      &-goback{
        margin-right: 16px;
      }
      &-text{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    &-list{
      display: flex;
      flex-direction: column;
      overflow: hidden;
      overflow-y: auto;
      height: calc(100% - 78px);
      width: 100%;

      &-selectedfolder, &-search{
        height: calc(100% - 78px - 45px);
      }
    }
  }

  &__large-panel{
    background-color: $background-lightblue;
    height: 100%;
    width: 45%;
    flex-grow: 1;
    flex: 1;
    position: relative;
  }

  &__dropzone--wrapper{
    position: relative;
    width: 100%;
    display: flex;
    height: 100vh;
    outline: unset;
  }

  &__search-count{
    background-color: #FFFFFF;
    border-bottom: 1px solid $lighter-gray;
    margin: 0 4px;
    padding: 8px 0;
    width: calc(100% - 16px);
  }

  &__search-count-text{
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    margin: 0 12px;
  }
}
