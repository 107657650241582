.admin-map__map {
  width: 100%;
  height: 376px;

  &-wrapper {
    padding: 8px;
  }
}

.admin-map__parameters {
  display: grid;
  align-items: start;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 24px;
  padding: 24px;

  &-button,
  .text-input__wrapper,
  .select-box {
    margin-bottom: 0;
  }

  .text-input__input,
  .dropdown,
  .button__wrapper {
    margin-bottom: 0;
    height: 44px;
  }

  &-button { padding-top: 2rem; }

}

.admin-settings__element__timezone{
  & > .select-box{
    max-width: 600px;
    & > .select-box__wrapper{
      height: 100%;
      margin-bottom: 0;
    }
  }
}
