.full-header{
  &__wrapper{
    display: flex;
    width: 100%;
    min-height: 136px;
    height: 136px;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    background-color: $background-lightblue;
    padding-left: 79px;
  }

  &__title{
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 1.25;
    display: flex;
    align-items: center;
    color: $black;
    margin-right: 26px;
  }

  &__subtitle{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.5;
    display: flex;
    align-items: center;
    color: $light-gray;
  }

  &__button{
    position: absolute;
    right: 32px;
    .select-box { margin-bottom: 0; }
  }
}
