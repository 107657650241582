.password-input{

  &__wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    // height: 100%;
    margin-bottom: 2rem;
  }

  &__label{
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    text-align: left;
    font-size: 1rem;
    line-height: 1.5;
    align-items: flex-start;
    color: $darker-gray;
    margin-bottom: 8px;
  }

  &__input{
    width: 100%;
    height: 3.5rem;
    border-radius: 6px;
    border: 1px solid $lighter-gray;
    outline: none;
    box-sizing: border-box;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;

    padding: 1rem 0.75rem;
    &-wrapper{
      position: relative;
    }
    &:focus{
      box-shadow: 0 0 0 3px rgba(56, 157, 108, 0.1);
      border: 1px solid $main-green;
    }
  }
  &__icon{
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 1.25rem;
    right: 1rem;
    cursor: pointer;
    &-svg{
      width: 1.125rem;
      height: 1.125rem;
    }
  }
}
