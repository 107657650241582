.create-report{
  &__wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }

  &__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 1280px;
    padding-top: 40px;
    padding-left: 80px;
    padding-right: 20%;
    padding-bottom: 200px;

    & > .select-box{
      margin-bottom: 32px;
    }
  }

  &__header{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.375rem;
    line-height: 1.1;
    color: $darker-gray;
    margin-bottom: 24px;
    &-files{
      margin-bottom: 8px;
    }
  }

  &__time{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
    flex-wrap: wrap;

    & > div:first-child{
      @include on-tablet{
        width: 100% !important;
      }
      width: 40% !important;
      margin-bottom: 0.375rem;
    }

    & > div {
      @include on-tablet{
        width: 30% !important;
      }
      width: 18.5% !important;
      margin-bottom: 0;
    }
  }

  &__map{
    width: 100%;
    display: flex;
    flex-direction: column;

    &--coordinates{
      display: flex;
      width: 100%;
      margin-top: 40px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      &-container{
        display: flex;
        justify-content: space-between;
        width: 100%;
        & > .text-input__wrapper{
          width: 47.5%;
          margin-bottom: 20px;
        }
      }
      &-containerdms{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        &-subcontainer{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          &-edit{
            @include on-large-device{
              .text-input__wrapper{
                width: 22.25% !important;
                .text-input__input{
                  height: 43.42px;
                }
              }
              .select-box{
                width: 22.25% !important;
              }
            }
          }
          @include on-tablet {
            .text-input__wrapper{
              width: 22.25% !important;
              .text-input__input{
                height: 43.42px;
              }
            }
            .select-box{
              width: 22.25% !important;
            }
          }
          .text-input__wrapper{
            width: 9%;
            .text-input__input{
              height: 43.42px;
            }
          }
          .select-box{
            width: 10%;
          }

          &-direction{
            width: 14%;
            @include on-tablet {
              width: 22.5%;
            }
            .select-box{
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  &__subtitle{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    color: $medium-gray;
    margin-bottom: 32px;
  }

  &__title{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5;
    color: $darker-gray;
  }

  &__media{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }

  &__media-uploaded{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__media-files{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__button-container{
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    width: 100%;

  }
}

.map__marker{
  width: 20px;
  height: 40px;
  background-image: url('../../../assets/images/map-marker.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
  //border: 2px solid $darker-gray;
}
