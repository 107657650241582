.user-container {
  width: 100%;

  &__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
  }
}

.admin_userprofile {
  &__box-area{
    padding-left: 80px;
    height: calc(100vh - 136px - 55px);
    overflow-y: scroll;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 50px;
    height: 100%;
  }

  &__userpic_box{
    margin-right: 70px;
  }

  &__title {
    color: $black;
    font-size: 1.375rem;
    font-weight: bold;
    font-family: 'SFPro-Text', sans-serif;
    margin-top: 0;
    margin-bottom: 36px;
    text-align: left;
  }

  &__userpic {
    width: 178px;
    height: 178px;
    border-width: 178px;
    border-color: $profile-blue; //Default color
    background-position: center;
    background-size: 178px 178px;
    background-color: $profile-blue; //Default color
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    color: $white;
    font-size: 2.25rem;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;


    &__edit{
      background-color: rgba($darker-gray, 0.7);
      height: 56px;
      width: 100%;
      bottom: 0;
      position: absolute;
      z-index: 2;
      align-items: center;
      color: $white;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      margin: 0;
      opacity: 1;
      cursor: pointer;
      overflow: hidden;

      &_text{
        color: $white;
        font-size: 1rem;
        height: 100%;
        width: 100%;
        font-family: 'SFPro-Text', sans-serif;
        font-weight: bold;
      }
    };
  }

  &__form{
    &_box{
      width: 356px;
      .text-input__wrapper{
        margin-bottom: 16px;
      }
    }
    &_submit{
      margin-top: 16px;
      padding-bottom: 20px;
    }
  }
}

