.messages-container{
  width: 100%;
  &__wrapper {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 45px;
    padding-bottom: 45px;
    display: flex;
    width: '100%';
    justify-content: space-between;
    flex-wrap: wrap;
    height: calc(100vh - 136px);
    overflow: hidden;
    overflow-y: auto;

    @include on-medium-device{
      padding-left: 50px;
      padding-right: 50px;
    }

    &__recents {
      width: 50%;
      padding-left: 16px;
      @include on-medium-device{
        padding-left: 0;
        padding-top: 20px;
        width: 100%
      }

      &-box{
        margin-top: 46px;
        border: 1px solid $lighter-gray;
        border-radius: 6px;
        max-height: 600px;
        overflow-y: scroll;
        width: 100%;
      }
    }
  }
  &__title {
    color: $darkest-gray;
    font-size: 1.375rem;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    text-align: left;
  }
}
