//$letter-spacing-fonts : 0.5px;

%sfpro, .sfpro {
    font-family: 'SFPro-Text', sans-serif;
}

@font-face{
  font-family: "SFPro-Text";
  src: url("../../assets/fonts/SFProText-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face{
  font-family: "SFPro-Text";
  src: url("../../assets/fonts/SFProText-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family: "SFPro-Text";
  src: url("../../assets/fonts/SFProText-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family: "SFPro-Text";
  src: url("../../assets/fonts/SFProText-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face{
  font-family: "SFPro-Text";
  src: url("../../assets/fonts/SFProText-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
