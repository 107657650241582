.organization-list-content{
  &__wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1;
    margin-bottom: 1rem;

    & > .text-input__wrapper, > .select-box{
      width: 40%;
      padding-left: 40px;
      margin-right: 10px;
    }

    &--create > .text-input__wrapper, > .select-box{
      width: 100%;
      padding: 0 40px;
      margin-right: auto;
    }
  }

  &__actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 40px;

    .button__wrapper {
      width: 146px;
      margin: 0;
    }
  }

}
