.story-info{
  &-title{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: bold;
    text-align: left;
    font-size: 2.625rem;
    line-height: 1;
    letter-spacing: -0.5px;
    color: $black;
    padding-bottom: 25px;
    word-break: break-all;
  }
  &-subtitle {
    font-family: 'SFPro-Text', sans-serif;
    font-weight: normal;
    font-size: 1.75rem;
    line-height: 1.25;
    color: $dark-gray;
    padding-bottom: 40px;
    text-align: left;
  }

  &-image {
    width: 800px;
    height: 408px;
    background: $lightest-gray; // Switch for background-image
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-bottom: 40px;
    text-align: left;
    border-radius: 8px;
  }

  &-titlebox {
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1.125rem;
    line-height: 1.33;
    color: $black;
    padding-bottom: 40px;
    text-align: justify;
    word-break: break-all;
  }
}
