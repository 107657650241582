.triangle-button{
  &__wrapper{
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../assets/images/triangle_button.svg');
    background-position: center;
    width: 56px;
    height: 40px;
    cursor: pointer;
    margin-right: 8px;

    &:hover{
      filter: grayscale(100%) sepia(100%);
    }
  }
}
