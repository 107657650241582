.admin-export-report{

  &-halfcontainer {
    width: 50%;
    @include on-large-device{
      width: 100%;
    }
  }

  &-search{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    &-bar{
      width: 75%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-checkbox{
        margin-top: -5px;
        margin-right: 40px;
        &.ui.checkbox > label::before{
          border-color: $black;
        }
      }
    }

    &-date {
      width: 25%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      & > .react-datepicker-wrapper{
        & > .react-datepicker__input-container{
          display: flex;
          width: 100%;
          justify-content: flex-end;
          align-items: center;
          & > .react-datepicker__close-icon{
            position: initial;
            margin-left: 8px;
            padding: 0 !important;
            display: flex;
            /* width: 20px; */
            align-items: center;
            &::after{
              padding: 0 0 2px 0 !important;
              height: initial;
            }
          }
        }
      }
    }
  }

  &-results{

    &-container{
      border-top: 1px solid $lighter-gray;
      height: 500px;
      overflow-y: scroll;
    }

    position: relative;
    margin-top: -1px;

    &-checkbox{
      position: absolute !important;
      left: 0; top: 50%;
      margin-top: -0.75rem;
      z-index: 3;

      &.ui.checkbox > label::before{
        border-color: $black;
      }
    }

    &:hover{
      background-color: $background-lightgreen;
      cursor: pointer;
      & > .report-info__wrapper{
        &:before { visibility: visible; }
        background-color: $background-lightgreen;
      }
    }

    .report-info__wrapper {
      width: 100%;
      margin: 0;
      padding-left: 1.5rem;
    }
  }

  &-exportbutton {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    & > .button__wrapper--medium {
      padding: 0 20px;
      width: initial;
      margin-bottom: 0;
    }
  }
}
