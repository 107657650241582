.uploaded-file{
  &__wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 121px;
    padding: 32px;
    border-radius: 8px;
    border: 1px solid $lighter-gray;
    margin-bottom: 15px;
    position: relative;
  }

  &__title{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.33;
    color: $darker-gray;
  }

  &__icons-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: -8px;
    &-icon {
      margin-right: 8px;
      margin-left: 8px;
      &:last-child{
        margin-right: 0;
      }
    }
  }

  &__icon{
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-right: 26px;

    &--video{
      background-image: url('../../../assets/images/media_video.svg');
    }
    &--audio{
      background-image: url('../../../assets/images/media_audio.svg');
    }
    &--image{
      background-image: url('../../../assets/images/media_image.svg');
    }
    &--pdf{
      background-image: url('../../../assets/images/media_pdf.svg');
    }
    &--csv{
      background-image: url('../../../assets/images/media_csv.svg');
    }
  }

  &__close{
    position: absolute;
    top: 32px;
    right: 32px;
  }
}
