.alert-container{
  position: absolute;
  flex-direction: column-reverse;
  flex: 1;
  display: flex;
  bottom: 40px;
  left: 100px;
  z-index: 1002;

  .split-view__wrapper + & {
    left: calc(60% + 20px)
  }
}

.alert-message{
  &-appear {
    opacity: 0;
  }
  &-appear-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  &-appear-done{
    opacity: 1;
  }
  &-enter {
    opacity: 0;
  }
  &-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  &-enter-done {
    opacity: 1;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active{
    opacity: 0;
    transition: opacity 300ms;
    display: none;
  }
  &-exit-done {
    opacity: 0;
    display: none !important;

  }
  &__wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 352px;
    min-height: 72px;
    background-color: $darkest-gray;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    padding: 16px;
    z-index: 1002;
    margin-top: 10px;
  }

  &__icon{
    background-repeat: no-repeat;
    background-size: 50%;
    margin-right: 16px;
    flex-shrink: 0;

    &--success{
      width: 40px;
      height: 40px;
      background-position: 50% 50%;
      border-radius: 50%;
      border: 1px solid $light-green;
      background-image: url('../../../assets/images/green-checkmark.svg');
    }

    &--error{
      width: 40px;
      height: 40px;
      background-position: 50% 40%;
      border-radius: 50%;
      border: 1px solid $system-red;
      background-image: url('../../../assets/images/red_warning.svg');
    }
  }

  &__message{
    font-family: 'SFPro-Text', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    color: $white;
    text-align: left;
    width: calc(100% - 40px);
  }

  &__hidden{
    display: none !important;
  }
}
