.text-input{

  &__wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    textarea { resize: none; }
  }

  &__label{
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    text-align: left;
    font-size: 1rem;
    line-height: 1.5;
    align-items: flex-start;
    color: $darker-gray;
    margin-bottom: 0.5rem;
  }

  &__input{
    width: 100%; min-height: 3.325rem;
    border-radius: 0.375rem;
    border: 1px solid $lighter-gray;
    outline: none;
    box-sizing: border-box;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0.375rem;
    padding: 0.75em 1em;

    &::placeholder {
      color: $smooth-gray;
      opacity: 1;
    }

    &__error{
      border: 1px solid $system-red !important;
      &:focus{
        box-shadow: 0 0 0 3px rgba($system-red, 0.1) !important;
        border: 1px solid $system-red !important;
      }
    }
    &:not(:read-only):focus{
      box-shadow: 0 0 0 3px rgba(56, 157, 108, 0.1);
      border: 1px solid $main-green;
    }

    &__tagplaceholder{
      &:hover{
        border-color: $main-green;
      }
      &::placeholder{
        color: $smooth-gray;
      }
    }

    &:read-only {
      border-color: $lighter-gray;
      color: $medium-gray;
    }

  }
  &__error {
    font-size: 0.8125rem;
    font-family: 'SFPro-Text', sans-serif;
    text-align: left;
    color: $system-red;
  }

}
