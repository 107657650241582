.admin-settings {
  &__element {
    &-publicinputs {
      margin-top: 20px;
      > .select-box, .text-input__wrapper{
        margin-bottom: 40px;
      }

      > .select-box .text-input__label, .text-input__wrapper .text-input__label {
        font-weight: normal;
      }

      & > .text-input__wrapper input[type="color"] {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.25rem;
      }
    }

    &-public-uploads{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &-container {
        width: 48% !important;
        @include on-medium-device{
          width: 100% !important;
        }
      }
      &-title {
        width: 100%;
        font-family: 'SFPro-Text', sans-serif;
        text-align: left;
        font-size: 1rem;
        line-height: 1.5;
        color: $black;
        margin-bottom: 8px;
      }
    }
  }
}
