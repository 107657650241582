.message-element{
  &-container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    &:hover{
      background-color: $lightest-gray;
    }
    &:first-child{
      & > .message-element{
        border-top: none;
        padding-top: 1.5rem;
      }
    }
  }
  width: 100%;
  min-height: 130px;
  border-top: 1px solid $lighter-gray;
  padding-top: 1rem;
  padding-bottom: 1rem;
  &-header{
    display:flex;
    justify-content: space-between;
    padding-bottom: 10px;
    &-title{
      color: $darkest-gray;
      font-family: 'SFPro-Text', sans-serif;
      font-weight: bold;
      font-size: 1rem;
      // word-break: break-all;
      margin-right: 10px;
      &-container{
        text-align: left;
      }
    }
    &-date{
      color: rgba(0, 0, 0, 0.38);
      font-family: 'SFPro-Text', sans-serif;
      font-weight: normal;
      font-size: 0.875rem;
    }
  }

  &-link{
    color: #16675D;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    &:hover, &:focus{
      text-decoration: underline;
    }
    &-seemore{
      word-break: normal;
    }

  }
  &-body {
    color: rgba(0, 0, 0, 0.87);
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
  }

  &-footer{
    width: 100%;
    text-align: left;
    margin-top: 7px;
  }

  &-modal{
    width: 650px !important;
    @include on-medium-device{
      width: 450px !important;
    }
  }
  &-recipient {
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1rem;
    text-align: left;
    margin-bottom: 1.5rem;
  }
}
