.delete-modal{

  &__wrapper{
    width: 620px !important;
    height: 320px !important;
    border-radius: 8px;
    padding-bottom: 60px;
    padding-top: 60px;
  }
  &__container{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    &-header{
      &-title{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'SFPro-Text', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 2.0625rem;
        line-height: 1;
        color: $black;
      }
      &-subtitle{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'SFPro-Text', sans-serif;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 1.44;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1.5rem;
        color: $black;
      }
    }

    &-actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      & > .button__wrapper {
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 0;
        height: 48px;
        width: 160px;
      }
    }
  }
}
