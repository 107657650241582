.link-ref{
  font-family: 'SFPro-Text', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  text-align: center;
  color: $main-green;
  text-decoration: none;
  padding-bottom: 28px;

  &-disabled{
    color: $lighter-gray !important;
    cursor: not-allowed;
    &:hover{
      color: $lighter-gray !important;
      text-decoration: none !important;
    }
  }

  &:hover{
    color: $light-green;
    text-decoration: underline;
  }
}
