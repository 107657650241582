
.report-media {
  margin-top: 12px;
  margin-bottom: 36px;

  &--editmode {
    padding: 26px;
    border-radius: 8px;
    border: 1px solid $lighter-gray;
  }

  &.media-type-pdf  {
    padding: 26px;
    border-radius: 8px;
    border: 1px solid $lighter-gray;

    .report-media__file {
      display: flex;
      align-items: center;

      &-filename {
        flex: 1;
        text-align: left;
        padding-left: 26px;
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }

  &.media-type-audio  {

    .mediaplayer {
      width: 100% !important;
      height: 56px !important;
    }
  }

  &.media-type-video  {

    .report-media-container {
      max-width: 640px;
    }

    .mediaplayer {
      position: relative;
      width: 100% !important;
      height: 0 !important;
      padding-bottom: 56.25%;
    }

    video {
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
      height: auto !important;
    }
  }

  &__caption-input {
    margin: 15px 0 0;
  }

  &__caption {
    display: flex;
    align-items: baseline;
    grid-column: span 2;
    margin-top: 10px;

    > * {
      margin-right: 10px;
    }

    &-svg {
      margin-top: 2px;
    }

    &-label {
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5;
      color: $black;
    }

    &-text {
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5;
      color: $darkest-gray;
    }

  }

}
