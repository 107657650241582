.ui.checkbox.custom-radio__wrapper > label{
  font-family: 'SFPro-Text', sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  color: $black;

  &:before, &:after {
    width: 1.3225rem !important;
    height: 1.3225rem !important;
  }

  &:after{
    background-color: $main-green !important;
    transform: scale(0.9) !important;
    border: 2px solid white !important;
  }
}

.ui.checked.checkbox.custom-radio__wrapper > label:before{
  border-color: $main-green !important;
}
