.story-create {
  &__container{
    background-color: $white;
    height: 100%;
    width: 45%;
    flex-grow: 1;
    position: relative;
    padding-top: 16px;
    padding-bottom: 32px;
    overflow-y: scroll;
    border-left: 1px solid $lighter-gray;
  }
  &__header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    & > .button__wrapper {
      margin-bottom: 0;
      height: 40px;
      min-width: 170px;
      width: initial;
      padding-left: 10px;
      padding-right: 10px;
    }
    & > .toggle__wrapper {
      align-items: initial;
    }
  }
  &__body{
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 32px;
    padding-bottom: 20px;
    max-width: 1100px;
    &-addcontainer{
      border-width: 2px;
      border-style: dashed;
      border-color: $light-gray;
      height: 56px;
      border-radius: 8px;
      margin-bottom: 24px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &-text{
        font-size: 1rem;
        font-family: 'SFPro-Text', sans-serif;
        font-weight: bold;
        color: $light-gray;
      }
    }

    &-input-title {
      font-size: 1rem;
      font-weight: bold;
      font-family: 'SFPro-Text', sans-serif;
      color: $darkest-gray;
      text-align: left;
      padding-bottom: 16px;
    }
    &-cover-image {
      position: relative;
      width: 100%;
      height: 408px;
      background: $lightest-gray;
      border-radius: 8px;
      margin-bottom: 20px;
      &-editbutton{
        position: absolute;
        right: 39px;
        bottom: 34px;
        &-input{
          display: none;
        }
        & > .button__wrapper {
          margin-bottom: 0;
        }
      }
    }
    &-titleinput {
      height: 5.25rem;
      font-size: 2.25rem;
      line-height: 1.25rem;
      border: none;
      color: $darkest-gray;
      margin-bottom: 1rem;
      font-family: 'SFPro-Text', sans-serif;
      &::placeholder{
        color: $medium-gray;
      }
      &:focus {
        border: none;
        box-shadow: none;
        background: $lightest-gray
      }
    }
    &-subtitleinput {
      font-size: 1.5rem;
      line-height: 2;
      font-family: 'SFPro-Text', sans-serif;
      color: $darkest-gray;
      border: none;
      &::placeholder{
        color: $medium-gray;
      }
      &:focus {
        border: none;
        box-shadow: none;
        background: $lightest-gray
      }
    }

    &-optionalinputs {
      min-height: 100px;
    }

    &-textarea{
      padding-bottom: 22px;
      padding-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 16px;
      border: 1px solid $lighter-gray;
      outline-color: transparent !important;
      border-radius: 8px;
      position: relative;
      &:focus-within{
        border: 1px solid $main-green;
      }
      &-box{
        width: 100%;
        text-align: left;
      }
      &-edit{
        position: absolute;
        right: 5px;
        top: 5px;
      }
      &-input {
        width: 100%;
        resize: none;
        font-family: 'SFPro-Text', sans-serif;
        font-size: 1.125rem;
        line-height: 1.33;
        padding-top: 14px;
        color: $black;
        border: none !important;

        box-shadow: none !important;
        background: inherit;
        &:focus {
          outline-color: transparent;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
  &__submit{
    display: flex;
    justify-content: flex-end;
  }
}
.story-create__body-textarea-input:focus-visible{
  outline-color: transparent !important;
  outline: none;
  border: none;
  box-shadow: none !important;
}
