.report-basic-info{

  &-header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 26px;
  }
  &-date{
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    color: $medium-gray;
  }
  &-status{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-verify{
      fill: $lighter-green;
      margin-right: 8px;
    }

    &-unverify{
      fill: $system-yellow;
      margin-right: 8px;
    }

    &-title{
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5;
      font-family: 'SFPro-Text', sans-serif;
      color: $darkest-gray;
      margin-right: 18px;
    }
    &-title:last-child{
      margin-right: 2px;
    }
  }
  &-title{
    font-family: 'SFPro-Text', sans-serif;
    font-weight: bold;
    font-size: 2.0625rem;
    line-height: 1;
    letter-spacing: -0.02em;
    color: $darkest-gray;
    text-align: left;
  }

  &-author{
    font-family: 'SFPro-Text', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    color: $medium-gray;
    text-align: left;
    padding-top: 11px;
  }
}
