.autosuggest {

  &-wrapper {
    position: relative;
    flex-grow: 1;
    height: 3.325rem;

    .icon.clear {
      position: absolute;
      right: 6px; top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__container {
    position: absolute;
    top: 0; left: 0; right: 0;
    border-radius: 2px;

    &--open {
      box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.35) !important;

      .footer {
        display: block;
      }
    }
  }

  &__input {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    background-color: $lightest-gray;
    border: 1px solid $lightest-gray;
    outline: none;
    box-sizing: border-box;
    font-family: 'SFPro-Text', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    padding: 16px 12px;

    &::placeholder {
      color: $smooth-gray;
      opacity: 1;
    }

    &--open {
      background-color: $white;
    }
  }
}

.footer {
  background-color: $white;
  color: $main-green;
  cursor: pointer;
  display: none;
  font-family: 'SFPro-Text', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  padding: 8px 16px;
  text-align: left;

  &:hover {
    background-color: $lightest-gray;
  }
}

.suggestions-list {
  list-style: none;
  margin: 0; padding: 0;
  text-align: left;

  max-height: 50vh;
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
}

.suggestion {
  font-family: 'SFPro-Text', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  padding: 8px 16px;
  cursor: pointer;

  &--highlighted {
    background-color: $lightest-gray;
  }

  &__title {
    color: $darker-gray;
    font-weight: bold;
    text-align: left;
    overflow: hidden;
    max-width: 98.5%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &__description {
    font-weight: 400;
    color: $medium-gray;
    text-align: left;
    overflow: hidden;
    word-break: break-all;
    max-width: 95%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
