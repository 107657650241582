.admin-settings__element-open {

  .admin-settings-subsection {
    margin: 24px 0 0;
    padding: 16px 24px 0;
    border-top: 1px solid $lighter-gray;
    width: 100%;

    &__title {
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5;
      color: $black;
      text-align: left;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      text-align: left;
    }
  }
}

.admin-settings-lessons {
  display: flex;
  justify-content: space-between;

  .text-input__wrapper {
    margin: 16px 0;
  }

  .admin-settings__element {
    &-title {
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5;
      color: $black;
    }
    &-radio-groups {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      & > .custom-radio__wrapper{
        margin-right: 50px;
        @include on-medium-device{
          margin-right: 25px;
        }
        & label {
          &::before{
            top: -1px !important;
          }
          &::after{
            top: -1px !important;
          }
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}

.admin-settings-emergency-contact {
  .message-form-userselect {
    margin-bottom: 24px;
  }

  &__phone {
    display: flex;
    align-items: stretch;

    .select-box {
      width: 125px;
      margin-right: 8px;

      .dropdown {
        min-height: 0 !important;
      }
    }

    .text-input__wrapper {
      padding-top: 16px;
    }

  }

  &-elem-copy {
    font-family: 'SFPro-Text', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .add-link {
      color: #16675d; // @fixme
      font-weight: 700;
      cursor: pointer;
      &:hover { text-decoration: underline; }
    }

    > * { margin-top: 24px; }
  }
}
